<template>
  <el-form ref="form" :model="dataForm">
    <el-form-item label="选择触发的测试用户：">
      <el-select
          v-model="uids"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="请输入用户关键词"
          :remote-method="search"
          :loading="loading">
        <el-option
            v-for="item in userList"
            :key="item.uid"
            :label="item.nickname+'（'+item.uid+'）'"
            :value="item.uid">
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item style="margin-top: 20px">
      <el-button type="primary" :loading="testLoading" @click="submitForm">测试推送</el-button>
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  data () {
    return {
      testLoading: false,
      loading: false,
      userList: [],
      uids: [],
      dataForm: {
      }
    }
  },
  methods: {
    submitForm() {
      this.testLoading = true;
      this.$http({
        url: this.$http.adornUrl(`/admin/officialPushTask/triggerTest`),
        method: 'post',
        data: {
          uids: this.uids
        }
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$message({
            message: '推送成功',
            type: 'success',
            duration: 1500,
          })
        } else {
          this.$message.error(data.msg)
        }
        this.testLoading = false
      }).catch((err)=>{
        console.error(err)
        this.$message.error(err);
        this.testLoading = false
      })
    },
    search(keyword) {
      if (this.keyword !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.$http({
            url: this.$http.adornUrl(`/admin/user/searchKeyword?keyword=${keyword}`),
            method: 'get'
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.userList = data.result
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err)=>{
            console.error(err)
            this.$message.error(err);
          })
        }, 200);
      } else {
        this.userList = [];
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>